import React from "react"

import "./_hero.scss"

const UnsereErfahrung = () => (
    <section className="hero is-small is-link">
      <div className="hero-body">
        <div className="container">
          <h1 className="p-0 m-0">
            Social Engineering – der Risikofaktor Mensch im Informationsschutz
          </h1>
        </div>
      </div>
    </section>
  )
export default UnsereErfahrung
