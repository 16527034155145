import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/social-engingeering"

const SchulungenPage = () => (
  <Layout>
    <Seo title="Unsere Erfahrung" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <StaticImage
              src="../images/social-engineering.jpg"
              quality={65}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clavicon Profile"
              placeholder="tracedSVG"
            />
          </div>
          <div className="column is-three-fifths">
            <div className="content">
              <p>
                Cyber-Angriffe im Bereich der Wirtschafts- oder
                Industriespionage gegen deutsche und internationale Unternehmen
                werden zunehmend strategisch organisiert und durchgeführt. Viele
                Unternehmen wappnen sich meist mit einem hohen finanziellen und
                technischen Aufwand gegen mögliche Attacken. Während in einem
                derart abgesicherten System der Angreifer ab einem gewissen
                Punkt gestoppt wird, sind bei dem Menschen keinerlei Grenzen
                gesetzt. Der Mensch bleibt in diesem Szenario der Risikofaktor
                Nr. 1, da der Angreifer ohne großen Einsatz technischer Mittel
                an die gewünschten Informationen gelangen kann.
              </p>
              <p>
                Laut einer aktuellen Studie des Digitalverbandes Bitkom 
                {" ("}Bundesverband Informationswirtschaft, Telekommunikation und
                neue Medien e.V.{")"}, wurden in den vergangenen zwei Jahren 51%
                aller Unternehmen in Deutschland Opfer von digitaler
                Wirtschaftsspionage, Sabotage oder Datendiebstahl. Fast ein
                Fünftel {"("}19%{")"} der befragten Unternehmen registrierten dabei auch
                eine erhebliche Anzahl von Social Engineering- Angriffen. Der
                Studie folgend erlangten die Täter Unternehmensdaten nur zu 20%
                durch digitale Angriffe, jedoch zu 80% durch menschliche
                Manipulation. {"("}Bitkom, 2018{")"}
              </p>
              <p>
                Dabei spielen rein „menschliche“ Faktoren wie Gutgläubigkeit und
                Hilfsbereitschaft, aber auch Hierarchien innerhalb der
                Firmenstruktur, Manipulationen der Geschäftsleitung oder
                einzelner Mitarbeiter, welche aufgrund ihrer Funktion Zugang zu
                sensiblen Daten oder Geschäftsprozessen verantworten, eine
                entscheidende Rolle.
              </p>
              <p>
                Die durch Cyber-Kriminalität verursachten Schäden sind
                gravierend: Deutschland rangiert dabei nach den USA {" ("}108
                Mrd. US-Dollar{")"} und China {"("}60 Mrd. US-Dollar{")"} auf
                Platz 3 der Top-Ten-Wirtschaftsmächte, mit einem Gesamtschaden
                von ca. 51 Mrd. US-Dollar! Tendenz steigend. {"("}Allianz, 2018
                {")"}
              </p>
              <h4>Schulungsangebot</h4>
              <p>
                Die Schulung richtet sich primär an Sicherheitsmanager bzw.
                -berater, Mitglieder der Geschäftsleitung, Buchhaltung und
                IT-Administratoren. Diese werden nach dem Vortrag in der Lage
                sein, die Gefahren eines Social Engineering-Angriffes für ihr
                Unternehmen zu bewerten sowie eine bestehende Sicherheitslösung,
                unter Berücksichtigung der Gefahren einer Social
                Engineering-Attacke, kritisch zu hinterfragen.
              </p>
              <p>
                Dazu ist es erforderlich, den Teilnehmern der Veranstaltung mit
                den Möglichkeiten, Mitteln, Formen und Gefahren des Social
                Engineering vertraut zu machen. Um dieses Ziel zu erreichen,
                werden die folgenden Fragestellungen erläutert:
              </p>
              <ul>
                <li>Was ist Social Engineering?</li>
                <li>Warum funktioniert Social Engineering?</li>
                <li>Formen und Ausprägungen des Social Engineering?</li>
                <li>Rechtliche Aspekte zum Thema Social Engineering?</li>
                <li>Wie bereitet sich der Angreifer vor? </li>
                <li>Wie kann ich einen Angriff erkennen?</li>
                <li>
                  Sicherheitskultur und Leitlinien zur Abwehr von Social
                  Engineering als wichtige Säule der Existenzsicherung.
                </li>
                <li>
                  Welche Präventionsmaßnahmen kann ich in meinem Unternehmen
                  implementieren?
                </li>
                <li>
                  ISMS (Integrierte Sicherheits- und Managementsysteme) und
                  Compliance - verständlich und lesbar machen.
                </li>
              </ul>
              <p>
                Mit dieser Grundlageneinführung in das Social Engineering ist es
                Ihnen möglich, die Bedrohungssituation und das daraus
                resultierende Gefahrenpotential zu ermitteln und entsprechend zu
                bewerten. Hierdurch wird es der Geschäftsleitung ermöglicht, den
                entsprechenden Sicherheitslösungen die notwendige und
                erforderliche Aufmerksamkeit zu widmen und das Unternehmen vor
                einem perfiden Social Engineering-Angriff effektiv zu schützen.
              </p>
              <h4>Ablauf des Seminartages</h4>
              <p>
                Die Schulung findet in der Regel in der Zeit von 09.30h - ca.
                16.00h in den Tagungsräumen des Kastens Hotel Luisenhof in
                Hannover statt. Das Fünf Sterne Hotel ist nur ca. 300 m vom
                Hauptbahnhof Hannover entfernt. Den Teilnehmern wird im Rahmen
                des Seminars eine hochwertige Gastronomie angeboten, welches
                selbstverständlich in der Seminarpauschale enthalten ist.
              </p>
              <p>
                Der Seminar- und Schulungstag ist in zwei Einheiten
                strukturiert:
              </p>
              <ul>
                <li>
                  Teil 1: (09.30h - 12.00h) Einführung in das Thema:
                  Soziologische und psychologische Aspekte des Social
                  Engineering
                </li>
                <li>
                  Teil 2: (ca. 13.00h - 16.00h) Social Engineering aus Sicht der
                  IT mit praktischen Beispielen – Diskussion und Ausblick
                </li>
              </ul>
              <p>
                Die Seminare können auch bei Ihnen als Inhouse-Schulungen, oder
                an jedem beliebigen Ort, durchgeführt werden. Für ein
                individuelles Angebot nehmen Sie bitte Verbindung mit uns auf.
              </p>
              <blockquote>
                „Wenn Du den Feind und dich selbst kennst, brauchst du den
                Ausgang von hundert Schlachten nicht zu fürchten.“ Sunzi
                </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SchulungenPage
